<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top mb-10">
                <h2><span>■</span> 고객정보</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-200px">고객 <span class="red">*</span></th>
                                <td class="left pl-30">
                                    {{clientName}}
                                </td>
                                <th>연령선택 <span class="red">*</span></th>
                                <td class="left pl-20" >
                                    <select class="w-120px" v-model="ageRange">
                                        <option value="">연령선택</option>
                                        <option value="10대 이하">10대 이하</option>
                                        <option value="20대">20대</option>
                                        <option value="30대">30대</option>
                                        <option value="40대">40대</option>
                                        <option value="50대이상">50대이상</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">상담시간 <span class="red">*</span></th>
                                <td class="left pl-20" colspan="3">
                                    {{consDate}}<br>[시간] {{consTime}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">상담유형</th>
                                <td class="left pl-20" colspan="3">
                                    {{typeName}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">상담구분</th>
                                <td class="left pl-20" colspan="3"> 
                                    {{productName}}
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">상담주제 <span class="red">*</span></th>
                                <td class="left pl-5" colspan="3">
                                    <select class="w-120px" v-model="idxAgendaFirst" @change="changeAgenda">
                                        <option value="">상담대주제선택</option>
                                        <option v-for="(item,index) in agendList" :key="index" :value="item.idx">
                                            {{ item.cons_title }}
                                        </option>
                                    </select>
                                    <select class="w-200px ml-10" v-model="idxAgendaSecond">
                                        <option value="">상담소주제선택</option>
                                        <option v-for="(item,index) in agendSecondList" :key="index" :value="item.idx">
                                            {{ item.cons_title }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">사용가능회기</th>
                                <td class="left pl-20" colspan="3">
                                    {{consCountRest}} 회 (총{{consCount}}회)
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span> 상담정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-200px">전체 상담의 목표 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <textarea type="text" v-model.trim="goal" class="ml-10 h-40px w-85per" placeholder="최소 10글자 이상 작성해주세요." />
                                    </td>
                            </tr>
                            <tr>
                                <th>상담계획 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <textarea type="text" v-model="plan" class="ml-10 h-40px w-85per" placeholder="최소 10글자 이상 작성해주세요."/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="con_top mb-10">
                        <h2><span>■</span> 상담일지 작성</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>회기 유형 <span class="red">*</span></th>
                                <td class="left pl-30">
                                    <select class="w-200px" v-model="idxGenreMeta">
                                        <option value="">회기 유형 선택</option>
                                        <option v-for="(item,index) in genreList" :key="index" :value="item.idx">
                                            {{ item.cons_title }}
                                        </option>
                                    </select>
                                </td>
                                <th>상담 형태 <span class="red">*</span></th>
                                <td class="left pl-30">
                                    <select class="w-200px" v-model="idxTypeMeta">
                                        <option value="">상담 형태 선택</option>
                                        <option v-for="(item,index) in typeList" :key="index" :value="item.idx">
                                            {{ item.cons_title }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th class="w-200px">위험단계 <span class="red">*</span></th>
                                <td class="left pl-30" colspan="3">
                                    <select v-model="idxCrmMetaDanger" class="w-200px">
                                        <option value="-1">위험단계선택</option>
                                        <option v-for="(item, index) of dangerList" :key="index" :value="item.idx">{{item.danger_step_title}}</option>
                                        <option value="">기타</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>주호소문제 <span class="red">*</span></th>
                                <td class="left pl-20" colspan="3">
                                    <textarea type="text" v-model.trim="mainProblem" class="ml-10 h-40px w-85per" placeholder="최소 10글자 이상 작성해주세요." />
                                </td>
                            </tr>
                            <tr>
                                <th>상담과정</th>
                                <td class="left pl-20" colspan="3">
                                    <textarea type="text" v-model.trim="consProcess" class="ml-10 h-40px w-85per"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="bold w-200px">상담사 소견 <span class="red">*</span></th>
                                <td class="left pl-20" colspan="3">
                                    <textarea type="text" v-model.trim="consOpinion" class="ml-10 h-40px w-85per" placeholder="최소 10글자 이상 작성해주세요." />
                                </td>
                            </tr>
                            <tr>
                                <th>심리검사</th>
                                <td class="left pl-20" colspan="3">
                                    <textarea type="text" v-model.trim="mentalTest" class="ml-10 h-40px w-85per" value="" />
                                </td>
                            </tr>
                            <tr>
                                <th>이후계획 <span class="red">*</span></th>
                                <td class="left pl-20" colspan="3">
                                    <textarea type="text" v-model.trim="nextPlan" class="ml-10 h-40px w-85per" placeholder="최소 10글자 이상 작성해주세요." />
                                </td>
                            </tr>
                            <tr>
                                <th>상담내용 <span class="red">*</span></th>
                                <td class="left pl-20" colspan="3">
                                    <textarea type="text" v-model.trim="consContents" class="ml-10 h-40px w-85per" placeholder="최소 10글자 이상 작성해주세요." />
                                </td>
                            </tr>
                            <tr>
                                <th>첨부</th>
                                <td class="left pl-20" colspan="3">

                                <div class="mb-20">
                                    <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile.click()">파일 선택</button> 
                                    <span v-if="attachFile.name" class="ml-10 bold" style="cursor:pointer" >{{attachFile.name}}</span>
                                    <span class="error">※ 2mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span><br>
                                    <input v-show="false" ref="attachFile" type="file" @change="uploadFile($event, $refs.attachFile, 1)" />
                                    <span v-if="attachFileName && attachFileName != 'null'" class="ml-20" style="cursor:pointer" @click="viewFile(attachFileUrl)">{{attachFileName}} </span>
                                    <span v-if="attachFileName && attachFileName != 'null'" class="underline pointer" @click="removeRegFile(1)">삭제</span>
                                </div>

                                <div class="mb-20">
                                    <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile2.click()">파일 선택</button> 
                                    <span v-if="attachFile2.name" class="ml-10 bold" style="cursor:pointer" >{{attachFile2.name}}</span>
                                    <span class="error">※ 2mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span><br>
                                    <input v-show="false" ref="attachFile2" type="file" @change="uploadFile($event, $refs.attachFile2, 2)" />
                                    <span v-if="attachFileName2 && attachFileName2 != 'null'" class="ml-20" style="cursor:pointer" @click="viewFile(attachFileUrl2)">{{attachFileName2}} </span>
                                    <span v-if="attachFileName2 && attachFileName2 != 'null'" class="underline pointer" @click="removeRegFile(2)">삭제</span>
                                </div>

                                <div class="mb-20">
                                    <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile3.click()">파일 선택</button> 
                                    <span v-if="attachFile3.name" class="ml-10 bold" style="cursor:pointer" >{{attachFile3.name}}</span>
                                    <span class="error">※ 2mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span><br>
                                    <input v-show="false" ref="attachFile3" type="file" @change="uploadFile($event, $refs.attachFile3, 3)" />
                                    <span v-if="attachFileName3 && attachFileName3 != 'null'" class="ml-20" style="cursor:pointer" @click="viewFile(attachFileUrl3)">{{attachFileName3}} </span>
                                    <span v-if="attachFileName3 && attachFileName3 != 'null'" class="underline pointer" @click="removeRegFile(3)">삭제</span>
                                </div>

                                <div class="mb-20">
                                    <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile4.click()">파일 선택</button> 
                                    <span v-if="attachFile4.name" class="ml-10 bold" style="cursor:pointer" >{{attachFile4.name}}</span>
                                    <span class="error">※ 2mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span><br>
                                    <input v-show="false" ref="attachFile4" type="file" @change="uploadFile($event, $refs.attachFile4, 4)" />
                                    <span v-if="attachFileName4 && attachFileName4 != 'null'" class="ml-20" style="cursor:pointer" @click="viewFile(attachFileUrl4)">{{attachFileName4}} </span>
                                    <span v-if="attachFileName4 && attachFileName4 != 'null'" class="underline pointer" @click="removeRegFile(4)">삭제</span>
                                </div>

                                <div class="mb-20">
                                    <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile5.click()">파일 선택</button> 
                                    <span v-if="attachFile5.name" class="ml-10 bold" style="cursor:pointer" >{{attachFile5.name}}</span>
                                    <span class="error">※ 2mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span><br>
                                    <input v-show="false" ref="attachFile5" type="file" @change="uploadFile($event, $refs.attachFile5, 5)" />
                                    <span v-if="attachFileName5 && attachFileName5 != 'null'" class="ml-20" style="cursor:pointer" @click="viewFile(attachFileUrl5)">{{attachFileName5}} </span>
                                    <span v-if="attachFileName5 && attachFileName5 != 'null'" class="underline pointer" @click="removeRegFile(5)">삭제</span>
                                </div>

                                
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle pointer" @click="putReport()">수정</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
import {EmptyCheck} from '@/utils/validation.js'
export default {
    data: () => ({
        idxReport: -1,
        idxCrmReservation: -1,
        clientName: '',
        attachFile: '', // 상담일지 첨부파일
        attachFile2: '', 
        attachFile3: '', 
        attachFile4: '',
        attachFile5: '',
        
        attachFileName: '', // 상담일지 첨부파일
        attachFileName2: '', 
        attachFileName3: '',
        attachFileName4: '', 
        attachFileName5: '', 

        attachFileUrl: '', // 상담일지 첨부파일
        attachFileUrl2: '', 
        attachFileUrl3: '', 
        attachFileUrl4: '', 
        attachFileUrl5: '', 

        idxCrmMetaDanger: -1,
        idxCrmClient: -1,
        consOpinion: '',
        mentalTest: '',
        nextPlan: '',
        consContents: '',
        consProcess: '',
        processStatus: '미완료',
        goal: '',
        plan: '',
        mainProblem: '',
        dangerStepTitle: '',
        dangerList: [],
        idx: -1,
        consDate: '',
        consTime: '',
        productName: '',
        typeName: '',
        subjectName: '',
        consCount: 0,
        consCountRest: 0,
        permission: '',
        ageRange:'',
        agendList:[],
        agendSecondList:[],
        idxAgendaFirst:'',/* 상담대주제 */
        idxAgendaSecond:'',/* 상담소주제 */
        genreList:[],
        typeList:[],
        idxGenreMeta: '',/* 회기유형 */
        idxTypeMeta: '',/* 상담평태 */
    }),

    watch:{
        idxAgendaFirst(val){
            this.getAgendaSecondMeta(val);
        }
    },

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idxCrmReservation
        this.getReport(this.idx)
        this.getMetaList();
        this.getAgendaMeta(); /* 상담 대주제 리스트 */
        this.getGenreMeta();/* 회기유형 리스트 */
        this.getTypeMeta();/* 상담형태 리스트 */
    },

    methods: {

        // 사업자등록증 삭제
        removeRegFile(i) {
            switch (i) {
                case 1:
                this.attachFile = ''
                this.attachFileName = ''
                this.attachFileUrl = ''
                this.$refs.attachFile = ''
                    break;
                case 2:
                this.attachFile2 = ''
                this.attachFileName2 = ''
                this.attachFileUrl2 = ''
                this.$refs.attachFile2 = ''
                    break;
                case 3:
                this.attachFile3 = ''
                this.attachFileName3 = ''
                this.attachFileUrl3 = ''
                this.$refs.attachFile3 = ''
                    break;
                case 4:
                this.attachFile4 = ''
                this.attachFileName4 = ''
                this.attachFileUrl4 = ''
                this.$refs.attachFile4 = ''
                    break;
                case 5:
                this.attachFile5 = ''
                this.attachFileName5 = ''
                this.attachFileUrl5 = ''
                this.$refs.attachFile5 = ''
                    break;
                default:
                    alert('에러발생 관리자에게 문의해주세요');
                    break;
            }
            
        },

        // 상담일지 내용
        getReport(idx) {
            this.axios.get(`/api/v1/cons/report/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.ageRange = res.data.clientVO.ageRange ? res.data.clientVO.ageRange : '';
                        this.idxAgendaFirst = res.data.reportVO.idxAgendaFirst ? res.data.reportVO.idxAgendaFirst : '';
                        this.idxAgendaSecond = res.data.reportVO.idxAgendaSecond ? res.data.reportVO.idxAgendaSecond : '';
                        this.clientName = res.data.clientVO.name
                        this.consDate = res.data.reservationVO.consDate.slice(0, 4) + "년 " + res.data.reservationVO.consDate.slice(5, 7) + "월 " + res.data.reservationVO.consDate.slice(8, 10) + "일"
                        if(res.data.reservationVO.consTime.length >= 84) {
                            res.data.reservationVO.consTime = res.data.reservationVO.consTime.substring(0,84) + '\n' + res.data.reservationVO.consTime.substring(84)
                        }
                        this.consTime = res.data.reservationVO.consTime

                        this.productName = res.data.clientVO.productName
                        this.typeName = res.data.clientVO.typeName
                        this.subjectName = res.data.clientVO.subjectName
                        this.consCountRest = res.data.clientVO.consCountRest
                        this.dangerStepTitle = res.data.reportVO.dangerStepTitle
                        this.mainProblem = res.data.reportVO.mainProblem
                        this.consProcess = res.data.reportVO.consProcess
                        this.consOpinion = res.data.reportVO.consOpinion
                        this.mentalTest = res.data.reportVO.mentalTest
                        this.nextPlan = res.data.reportVO.nextPlan
                        this.consContents = res.data.reportVO.consContents

                        this.attachFileName = res.data.reportVO.attachFileName
                        this.attachFileName2 = res.data.reportVO.attachFileName2
                        this.attachFileName3 = res.data.reportVO.attachFileName3
                        this.attachFileName4 = res.data.reportVO.attachFileName4
                        this.attachFileName5 = res.data.reportVO.attachFileName5

                        this.attachFileUrl = res.data.reportVO.attachFileUrl
                        this.attachFileUrl2 = res.data.reportVO.attachFileUrl2
                        this.attachFileUrl3 = res.data.reportVO.attachFileUrl3
                        this.attachFileUrl4 = res.data.reportVO.attachFileUrl4
                        this.attachFileUrl5 = res.data.reportVO.attachFileUrl5

                        this.idxCrmClient = res.data.clientVO.idx
                        this.idxCrmReservation = res.data.reservationVO.idx

                        this.idxCrmMetaDanger = res.data.reportVO.idxCrmMetaDanger
                        this.dangerStepTitle = res.data.reportVO.dangerStepTitle

                        this.idx = res.data.reportVO.idx

                        this.goal = res.data.clientVO.goal
                        this.plan = res.data.clientVO.plan

                        this.permission = res.data.reportVO.permission;
                        this.idxGenreMeta = res.data.reportVO.idxGenreMeta ? res.data.reportVO.idxGenreMeta : '';
                        this.idxTypeMeta = res.data.reportVO.idxTypeMeta ? res.data.reportVO.idxTypeMeta : '';
                    }
                })
                .catch(err => {
                    
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 일지 등록
        putReport() {
            if(!this.idxAgendaFirst){
                alert('상담 대주제를 선택해주세요');
                return false;
            }

            if(!this.idxAgendaSecond){
                alert('상담 소주제를 선택해주세요');
                return false;
            }

            if(!EmptyCheck(this.ageRange,'연령선택을')){
                return false;
            }

            if(this.goal.length < 10){
                alert('전체 상담 목표를 10자 이상 작성해주세요')
                return false;
            }

            if(this.plan.length < 10){
                alert('상담계획을 10자 이상 작성해주세요')
                return false;
            }

            if(!EmptyCheck(this.idxGenreMeta.toString(),'회기유형을')){
                return false;
            }

            if(!EmptyCheck(this.idxTypeMeta.toString(),'상담 형태를')){
                return false;
            }

            if(this.idxCrmMetaDanger === -1 || this.idxCrmMetaDanger === '-1') {
                alert('위험단계를 선택해 주세요')
                return false
            }

            if(this.mainProblem.length < 10){
                alert('주호소문제를 10자 이상 작성해주세요')
                return false;
            }


            if(this.consOpinion.length < 10){
                alert('상담사소견를 10자 이상 작성해주세요')
                return false;
            }

            if(this.nextPlan.length < 10){
                alert('이후계획을 10자 이상 작성해주세요')
                return false;
            }

            if(this.consContents.length < 10){
                alert('상담내용을 10자 이상 작성해주세요')
                return false;
            }

            

            var params = new FormData();
            this.processStatus = "완료"
            params.append('ageRange',this.ageRange);
            params.append('idxAgendaFirst',this.idxAgendaFirst);
            params.append('idxAgendaSecond',this.idxAgendaSecond);
            params.append('idxCrmClient', this.idxCrmClient)
            params.append('idxCrmReservation', this.idxCrmReservation)
            params.append('consOpinion', this.consOpinion)
            params.append('mentalTest', this.mentalTest)
            params.append('nextPlan', this.nextPlan)
            params.append('consContents', this.consContents)
            params.append('processStatus', this.processStatus)
            params.append('consProcess', this.consProcess)
            params.append('idxCrmMetaDanger', this.idxCrmMetaDanger)
            for (var item of this.dangerList) {
                if (item.idx === this.idxCrmMetaDanger) {
                    this.dangerStepTitle = item.danger_step_title
                }
            }
            params.append('dangerStepTitle', this.dangerStepTitle)
            params.append('goal', this.goal)
            params.append('plan', this.plan)
            params.append('permission', 'Y');
            params.append('idxGenreMeta', this.idxGenreMeta);
            params.append('idxTypeMeta', this.idxTypeMeta);
            params.append('mainProblem', this.mainProblem)

            // 각 파일 빈 binary로 추가해놓기
            params.append('attachFile', new Blob())
            params.append('attachFile2', new Blob())
            params.append('attachFile3', new Blob())
            params.append('attachFile4', new Blob())
            params.append('attachFile5', new Blob())

            if (this.attachFile !== '') {
                params.delete('attachFile')
                params.append('attachFile', this.attachFile)
            } else {
                params.append('attachFileUrl', this.attachFileUrl)
                params.append('attachFileName', this.attachFileName)
            }

            if (this.attachFile2 !== '') {
                params.delete('attachFile2')
                params.append('attachFile2', this.attachFile2)
            } else {
                params.append('attachFileUrl2', this.attachFileUrl2)
                params.append('attachFileName2', this.attachFileName2)
            }

            if (this.attachFile3 !== '') {
                params.delete('attachFile3')
                params.append('attachFile3', this.attachFile3)
            } else {
                params.append('attachFileUrl3', this.attachFileUrl3)
                params.append('attachFileName3', this.attachFileName3)
            }

            if (this.attachFile4 !== '') {
                params.delete('attachFile4')
                params.append('attachFile4', this.attachFile4)
            } else {
                params.append('attachFileUrl4', this.attachFileUrl4)
                params.append('attachFileName4', this.attachFileName4)
            }

            if (this.attachFile5 !== '') {
                params.delete('attachFile5')
                params.append('attachFile5', this.attachFile5)
            } else {
                params.append('attachFileUrl5', this.attachFileUrl5)
                params.append('attachFileName5', this.attachFileName5)
            }

            
            this.axios.put(`/api/v1/cons/report/${this.idx}`, params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    if (res.data.err === 0) {
                        alert('수정이 완료되었습니다.');
                        history.back();
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.dangerList = res.data.dangerList
                    }
                })
        },

        // 상담일지 파일 업로드
        uploadFile(event, attachFile, i) {
            // // 포맷 검사
             var allowFormat = ['pdf', 'jpg', 'png', 'gif', 'PDF', 'JPG', 'PNG', 'GIF']
             var checked = false
             for (var item of allowFormat) {
                 if (event.target.files[0].name.includes(item)) {
                     checked = true
                     break
                 } else {
                     continue
                 }
            }

            if(checked) {
                // 용량 검사
                if (event.target.files[0].size > 1048576 * 20) {
                    alert('20MB 이하만 업로드 가능합니다.')
                    attachFile.value = ''
                    return false
                } else {

                    switch (i) {
                        case 1:
                        this.attachFile = event.target.files[0]       
                            break;
                        case 2:
                        this.attachFile2 = event.target.files[0]
                            break;
                        case 3:
                        this.attachFile3 = event.target.files[0]
                            break;
                        case 4:
                        this.attachFile4 = event.target.files[0]
                            break;
                        case 5:
                        this.attachFile5 = event.target.files[0]
                            break;
                    }
                }
            } else {
                alert('파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.')
                return false
            }
        },

        // 이전 페이지로 돌아가기
        cancel() {
            history.back()
        },

        // 상담
        modify() {

        },
        
        changeAgenda(){
            this.idxAgendaSecond='';
            if(!this.idxAgendaFirst){
                this.agendSecondList = [];
            }else{
                this.getAgendaSecondMeta(this.idxAgendaFirst);
            }
        },
        /**
         * agend대주제 리스트
         */
        getAgendaMeta(){
            this.axios.get('/api/v1/cons/report/agenda_meta',{}).then((response)=>{
                this.agendList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

        /**
         * @param {idx} 상위대주제
         */
        getAgendaSecondMeta(idx){
            this.axios.get(`/api/v1/cons/report/agenda_meta/${idx}`,{}).then((response)=>{
                this.agendSecondList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

        /**
         * 회기유형 목록
         */
        getGenreMeta(){
            this.axios.get(`/api/v1/cons/report/genre_meta`,{}).then((response)=>{
                this.genreList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

        /**
         * 상담형태 목록
         */
        getTypeMeta(){
            this.axios.get(`/api/v1/cons/report/type_meta`,{}).then((response)=>{
                this.typeList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },
        viewFile(url) {
            window.open(url,'','top=10, left=10, width=500, height=600')
        },
    }
}
</script>
<style lang="scss" scoped>
 .error{
     color: #FF0000;
 }
</style>